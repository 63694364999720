import React, { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import { SingleValue } from 'react-select';
import { Location } from '@components/Hotels/types';
import RoutingPath from '@constants/routingPath';
import { useRouter } from 'next/router';
import searchIcon from '@assets/images/search-icon.svg';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import useSessionStorage from '@hooks/useSessionStorage';
import { getDateWithDashes, getDateWithSlashes } from '@helpers/dateFormatter';
import { searchButtonClick, searchDateEntered, searchNewLocationSelected } from '@events/HomePage';
import { store } from '@context/store';
import useOutsideClick from '@hooks/useOutsideClick';
import { SEARCH } from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';
import useAmplitudePayloads from '@hooks/useAmplitudePayloads';
import { pastEscapedSearchDate } from '@helpers/date';
import ReactDatePicker from './ReactDatePicker/ReactDatePicker';
import calendarIcon from '../../assets/images/calendar-icon.svg';
import ButtonPrimary from './ButtonPrimary';
import ReactSelectSearch from './ReactSelectSearch/ReactSelectSearch';
import SearchSpinner from './SearchSpinner/SearchSpinner';
import PastDateError from './ReactDatePicker/PastDateError';

type Props = {
  hideCalendar?: boolean;
  isBottomSearchBar?: boolean;
  isShowLocationNearMe?: boolean;
  newTab?: boolean;
  applyFilters?: string[];
};
const defaultProps = {
  hideCalendar: false,
  isBottomSearchBar: false,
  isShowLocationNearMe: false,
  newTab: false,
  applyFilters: [],
};
interface SearchOption {
  readonly value: string;
  readonly label: string;
}
const searchBoxContainerStyle =
  'z-20 flex flex-col mx-auto max-w-2xl shadow-search -mt-10 border border-solid border-rp-gray-border rounded-md relative d:flex-row d:max-w-4xl';
const calendarStyle =
  'z-20 bg-white w-full py-3 flex items-center font-rp-pn-light text-lg d:w-80 d:flex-grow d:flex-shrink-0 px-5';
const searchButtonStyle =
  'z-10 h-full w-full text-base bg-white py-1 px-1 flex items-center relative rounded-b-5 d:w-40 d:rounded-r-5 d:-ml-2 d:p-10 d:z-20 d:mt-0';

export default function SearchBar({
  hideCalendar,
  isBottomSearchBar,
  isShowLocationNearMe,
  newTab,
  applyFilters,
}: Props) {
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [value, setValue] = useState<SingleValue<SearchOption>>();
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPastDateErrorBanner, setShowPastDateErrorBanner] = useState(false);
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
  const { generateSearchLocationPayload } = useAmplitudePayloads();
  const { track } = useEvents();
  const router = useRouter();
  const { setItem, getItem, removeItem } = useSessionStorage();

  const { state } = useContext(store);

  const [outsideClickRef] = useOutsideClick(() => {
    if (showPastDateErrorBanner) {
      setShowPastDateErrorBanner(false);
    }
  });

  useEffect(() => {
    setShowPastDateErrorBanner(state.showPastDateSelectedError);
  }, [state.showPastDateSelectedError]);

  useEffect(() => {
    if (locations && locations.length > 0 && value && value.value) {
      const selectedLocationValue = locations.filter((l) => l.name === value.value)[0];
      if (selectedLocationValue) setSelectedLocation(selectedLocationValue);
    }
  }, [locations, value]);

  useEffect(() => {
    if (selectedLocation) {
      searchNewLocationSelected(selectedLocation.name);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (startDate) {
      searchDateEntered(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    const storedDate = getItem(sessionStorageKeys.SMART_CALENDAR_DATE);
    if (storedDate) {
      setStartDate(new Date(storedDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStartDate = (dateValue: Date | undefined) => {
    const { dispatch } = useContext(store);

    if (dateValue) {
      setStartDate(dateValue);
      setItem(
        sessionStorageKeys.SMART_CALENDAR_DATE,
        getDateWithSlashes(pastEscapedSearchDate(dateValue, dispatch)),
      );
    }
  };

  const performSearch = (location: Location) => {
    if (location && location.name) {
      setItem(sessionStorageKeys.SEARCHED_LOCATION, JSON.stringify(location));
    }
    const filters =
      applyFilters && applyFilters.length > 0 ? `filters=${applyFilters.join(',')}` : '';
    if (location.type === 'hotel') {
      const locationEncoded = encodeURIComponent(location.name);
      const fullUrl = `${RoutingPath.SRP}?hotel_name=${locationEncoded}&city_id=${
        location.parent_id
      }&highlighted=true${filters ? `&${filters}` : ''}`;
      if (newTab) {
        window.open(fullUrl, '_blank');
        return;
      }
      router.push(fullUrl);
    } else {
      let fullUrl = location.url;

      if (filters) {
        const queryString = fullUrl.includes('?') ? '&' : '?';
        fullUrl += `${queryString}${filters}`;
      }

      if (newTab) {
        window.open(fullUrl, '_blank');
        return;
      }
      router.push(fullUrl);
    }
  };

  const trackSearchEvent = (userSelectedLocation: Location) => {
    // GA - Event
    searchButtonClick();

    const searchPropertyGroup = generateSearchLocationPayload(userSelectedLocation);

    track(
      SEARCH,
      {
        search_date: startDate ? getDateWithDashes(startDate) : null,
        ...searchPropertyGroup,
      },
      { withURLandTitle: true },
    );
  };

  const onSearchClick = () => {
    if (selectedLocation) {
      performSearch(selectedLocation);
      trackSearchEvent(selectedLocation);
    } else if (searchOptions && searchOptions.length > 0 && searchOptions[0].value !== 'error') {
      const firstOption = searchOptions[0];
      const selectedLocationValue = locations.filter((l) => l.name === firstOption.value)[0];
      performSearch(selectedLocationValue);
      trackSearchEvent(selectedLocationValue);
    } else if (value && value.value === '-1') {
      setItem(sessionStorageKeys.NO_RESULTS_SEARCH_TERM, value.label);
      removeItem(sessionStorageKeys.SEARCHED_LOCATION);
      router.push('/browse-hotels?no_results=true');
    } else {
      router.push('/browse-hotels');
    }
  };

  return (
    <div
      data-testid={isBottomSearchBar ? 'bottom-search-bar' : 'search-bar'}
      className={searchBoxContainerStyle}
    >
      <div className="bg-white flex w-full pt-3 relative rounded-t-md d:rounded-l-md d:rounded-tr-none d:pt-0">
        <div
          className={`z-20 bg-white pr-5 -mr-2 rounded-md items-center pl-5 border-b border-rp-gray-divider mt-4 d:pr-2 d:flex d:mt-0 ${
            isBottomSearchBar ? 'd:w-14' : ''
          }`}
        >
          {!isLoading && (
            <>
              <div className="hidden w-full mt-1 d:block">
                <Image src={searchIcon} height="22" width="24" alt="search-icon" />
              </div>
              <div className="block w-full d:hidden">
                <Image src={searchIcon} height="24" width="28" alt="search-icon" />
              </div>
            </>
          )}
          {isLoading && (
            <div className="w-full">
              <SearchSpinner />
            </div>
          )}
        </div>
        <ReactSelectSearch
          value={value}
          setValue={setValue}
          headerVariant={false}
          locations={locations}
          setLocations={setLocations}
          setIsLoading={setIsLoading}
          setSearchOptions={setSearchOptions}
          searchWithoutQuery={false}
          isBottomSearchBar={isBottomSearchBar}
          isShowLocationNearMe={isShowLocationNearMe}
        />
      </div>

      <div className={`${hideCalendar ? 'hidden' : ''} ${calendarStyle}`}>
        <div className="pr-5 pt-2 mt-0">
          <Image src={calendarIcon} alt="Calendar Icon" />
        </div>
        <ReactDatePicker
          startDate={startDate}
          setStartDate={updateStartDate}
          classes="font-rp-pn-regular text-rp-primary-black"
          showClearDate={false}
          scrollIntoView
        />
      </div>
      <div className={searchButtonStyle}>
        <ButtonPrimary onClick={onSearchClick} classes="d:absolute d:-ml-14">
          Search
        </ButtonPrimary>
      </div>

      {showPastDateErrorBanner ? (
        <PastDateError ref={outsideClickRef} setShow={setShowPastDateErrorBanner} />
      ) : null}
    </div>
  );
}
SearchBar.defaultProps = defaultProps;
