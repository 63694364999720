import React, { useEffect, useState, useContext } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import StaticDayPassesByLocation from '@components/DayPassesByLocation/StaticDayPassesByLocation';
import ExploreLocations from '@components/ExploreLocations/ExploreLocations';
import GiftCard from '@components/GiftCard/GiftCard';
import HotelPartner from '@components/HotelPartner/HotelPartner';
import CounterWithSearch from '@components/CounterWithSearch/CounterWithSearch';
import discover from '@assets/images/discover-daycations.svg';
import discoverMobile from '@assets/images/discover-daycations-mobile.svg';
import confident from '@assets/images/book-confidently.svg';
import confidentMobile from '@assets/images/book-confidently-mobile.svg';
import flexible from '@assets/images/flexible-support.svg';
import flexibleMobile from '@assets/images/flexible-support-mobile.svg';
import useIsMobile from '@hooks/useIsMobile';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import { useEvents } from '@events/EventsProvider';
import { VIEW_HOMEPAGE } from '@constants/amplitudeEvents';
import HotelApi from '@customTypes/hotel-api';
import { HomePageHotelsContext } from '@context/HomePageHotelsContext';
import HomePageMarketingModule from '@components/CaptureEmail/HomePageMarketingModule';
import HomePagePopularHotels from './HomePagePopularHotels';
import HomePageNearHotels from './HomePageNearHotels';
import HotelCardsLoadingSkeleton from './HotelCardsLoadingSkeleton';

type IndexPageProps = {
  popularHotels: PopularHotel[];
  userCityAndState: string;
};

export default function HomePage({ popularHotels, userCityAndState }: IndexPageProps) {
  const [selectedCityShort, setSelectedCityShort] = useState<string>();
  const [amplitudePageViewEventSent, setAmplitudePageViewEventSent] = useState<boolean>(false);

  const { amplitude, track } = useEvents();

  const isMobile = useIsMobile();
  const { homePageHotels, isFetchComplete, isLoading, selectedCity, selectedCityId } =
    useContext(HomePageHotelsContext);

  useEffect(() => {
    if (homePageHotels && selectedCity && homePageHotels.length > 0) {
      const stateCodeValue = homePageHotels[0].code;
      if (stateCodeValue) {
        const city = `${selectedCity.split(',')[0]}, ${stateCodeValue}`;
        setSelectedCityShort(city);
      }
    }
  }, [homePageHotels, selectedCity]);

  useEffect(() => {
    if (!amplitudePageViewEventSent && amplitude && isFetchComplete) {
      const hotels: HotelApi[] | PopularHotel[] =
        homePageHotels.length > 0 ? homePageHotels : popularHotels;

      const hotSpotHotels = (hotels as (HotelApi | PopularHotel)[]).filter(
        (hotel) => !!hotel.hotSpot,
      );

      track(VIEW_HOMEPAGE, {
        hot_spot_hotels: hotSpotHotels.map((hotel) => hotel.name),
      });

      setAmplitudePageViewEventSent(true);
    }
  }, [
    amplitude,
    amplitudePageViewEventSent,
    homePageHotels,
    isFetchComplete,
    popularHotels,
    track,
  ]);

  const defaultPageTitle = 'ResortPass | Book a Day Pass to a Hotel or Resort starting at only $25';

  return (
    <>
      <Head>
        <title>{defaultPageTitle}</title>
        <meta property="og:title" content={defaultPageTitle} />
        <meta
          name="description"
          content="Book a hotel pool or spa day pass, starting at just $25. Enjoy 1,500+ world-class resorts, without the overnight stay. No membership required."
        />
      </Head>
      <main className="flex flex-col">
        <div className="flex flex-col">
          <div className="flex max-w-8xl mx-auto py-14 gap-10 flex-col pl-3 pr-4 d:max-w-8xl d:flex-row d:px-6">
            <div className="flex items-center">
              <div className="relative h-10 w-24 mb-1 pl-2 d:pl-0">
                <Image src={isMobile ? discoverMobile : discover} alt="discover" />
              </div>
              <div className="flex-col d:ml-4">
                <p className="font-rp-pn-semi-bold text-base">Discover Daycations</p>
                <p className="font-rp-pn-regular leading-20px text-sm">
                  Select a date and explore pool, spa, beach access and more, at 1,000+ top hotels.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="relative h-10 w-24 mb-1 pl-2 d:pl-0">
                <Image src={isMobile ? confidentMobile : confident} alt="confident" />
              </div>
              <div className="flex-col d:ml-4">
                <p className="font-rp-pn-semi-bold text-base">Book Confidently</p>
                <p className="font-rp-pn-regular leading-20px text-sm">
                  After booking, receive check-in instructions, parking details, and all necessary
                  information.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="relative h-10 w-24 mb-1 pl-2 d:pl-0">
                <Image src={isMobile ? flexibleMobile : flexible} alt="discover" />
              </div>
              <div className="flex-col d:ml-4">
                <p className="font-rp-pn-semi-bold text-base">Flexible Support and Cancellation</p>
                <p className="font-rp-pn-regular leading-20px text-sm">
                  Invite guests or cancel bookings as needed with support on our website or app.
                </p>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? <HotelCardsLoadingSkeleton /> : null}

        {!isLoading && homePageHotels && homePageHotels.length === 0 && popularHotels && (
          <HomePagePopularHotels popularHotels={popularHotels} />
        )}

        {!isLoading && homePageHotels && homePageHotels.length > 0 ? (
          <HomePageNearHotels
            hotels={homePageHotels}
            selectedCityId={selectedCityId}
            selectedCityShort={selectedCityShort}
            userCityAndState={userCityAndState}
          />
        ) : null}

        <div className="px-6 mt-10">
          <HomePageMarketingModule />
        </div>

        <div className="pt-10">
          <ExploreLocations />
        </div>
        <GiftCard />
        <HotelPartner />
        <CounterWithSearch />
        <StaticDayPassesByLocation />
      </main>
    </>
  );
}
