/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import { getPopularHotels } from '@utils/services';
import { useCookies } from 'react-cookie';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { homePageView } from '@events/HomePage';
import HomePage from '@components/HomePage/HomePage';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import { store } from '@context/store';
import Layout from '@components/Layout/Layout';

type IndexPageProps = {
  popularHotels: PopularHotel[];
};

const getPopularHotelsData = async () =>
  getPopularHotels()
    .then((response) => ({
      showPopularHotels: true,
      popularHotels: response?.hotels,
    }))
    .catch(() => ({
      showPopularHotels: false,
      popularHotels: null,
    }));

export async function getServerSideProps() {
  const popularHotels = await getPopularHotelsData();
  return {
    props: {
      ...popularHotels,
    },
  };
}

export default function Home({ popularHotels }: IndexPageProps) {
  const [homePageViewFired, setHomePageViewFired] = useState<boolean>(false);

  const { getItem } = useSessionStorage();
  const date = getItem(sessionStorageKeys.SMART_CALENDAR_DATE);
  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const [cookies] = useCookies();

  const {
    state: { userCityAndState },
  } = useContext(store);

  // GA4 Page View Event
  useEffect(() => {
    if (!homePageViewFired && sessionID) {
      homePageView(cookies.userInformation, sessionID, date);
      setHomePageViewFired(true);
    }
  }, [cookies.userInformation, date, homePageViewFired, sessionID]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return <HomePage popularHotels={popularHotels} userCityAndState={userCityAndState} />;
}

Home.getLayout = (page: React.ReactElement) => <Layout searchBarEnabled={false}>{page}</Layout>;
