import React, { useEffect, useState } from 'react';
import useIsMobile from '@hooks/useIsMobile';
import ImageFallback from '@components/common/Image/ImageFallback';
import { getCdnImageUrl } from '@utils/cloudflare';
import { getCookieValue } from '@helpers/cookies';
import EmailCollectionForm from '@components/HomePage/MarketingModule/EmailCollectionForm';
import EmailConfirmation from '@components/HomePage/MarketingModule/EmailConfirmation';
import EmailThankYou from '@components/HomePage/MarketingModule/EmailThankYou';

export default function HomePageMarketingModule() {
  const [currentStep, setCurrentStep] = useState(1);
  const [, setIsFormSubmitted] = useState(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    const mailOptInCookie = getCookieValue('mails_opt_in');

    if (mailOptInCookie && mailOptInCookie === 'true') {
      setCurrentStep(3);
    }
  }, []);

  return (
    <div className="bg-rp-navy flex flex-col items-center justify-center mt-10 pb-10 pt-172px relative rounded-xl w-full d:flex-row d:pb-50px d:pt-58px d:h-370 d:mt-10 d:mb-10">
      <div
        className="absolute -top-32px d:relative d:top-0"
        style={{ width: isMobile ? '233px' : '544px', height: isMobile ? '180px' : '420px' }}
      >
        <ImageFallback
          src={getCdnImageUrl(
            544,
            'home-v2/marketing_module_v2.png',
            'https://static-assets.resortpass.com/assets/',
          )}
          loading="lazy"
          layout="fill"
          className="rounded-xl"
          alt="women in steamy sauna"
        />
      </div>

      <div className="flex flex-col max-w-549 ml-0 space-y-6 px-6 text-white d:px-0 d:ml-11 d:pr-11 w-full">
        {currentStep === 1 && (
          <EmailCollectionForm
            setCurrentStep={setCurrentStep}
            setIsFormSubmitted={setIsFormSubmitted}
          />
        )}

        {currentStep === 2 && <EmailConfirmation />}

        {currentStep === 3 && <EmailThankYou />}
      </div>
    </div>
  );
}
